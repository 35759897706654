<template>
  <v-btn
    dark
    fab
    small
    color="green darken-2"
    @click="$emit('click')"
  >
    <v-icon>mdi-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ActionUpdate'
}
</script>
