<template>
  <v-combobox
    v-bind="$attrs"
    multiple
    small-chips
    deletable-chips
    persistent-hint
    dense
    outlined
    clearable
    v-on="$listeners"
  >
    <template #append>
      <span />
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'VDataTableFilterMultipleValues',
  inheritAttrs: false
}
</script>
