<template>
  <v-btn
    dark
    fab
    small
    color="blue"
    data-test="btn-create"
    class="mr-3"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ActionAdd',
  inheritAttrs: false
}
</script>
