<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-icon
        small
        class="mr-1"
        v-on="on"
        @click="$emit('click')"
      >
        {{ icon }}
      </v-icon>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'VDataTableActionsEdit',
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>
