var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"transition-ease-in-out",attrs:{"cols":"12","md":_vm.filterShow === false ? 12 : 9}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('messages.answer'))+" ")]),_c('div',{staticClass:"table-toolbar"},[(_vm.selected.length > 0 && _vm.$can('answer.delete'))?_c('remove',{on:{"click":_vm.remove}}):_vm._e(),(_vm.$can('answer.create'))?_c('add',{attrs:{"to":{ name: 'dashboard.answer-create' }}}):_vm._e(),_c('filters',{on:{"click":function($event){$event.stopPropagation();_vm.filterShow = !_vm.filterShow}},model:{value:(_vm.filterShow),callback:function ($$v) {_vm.filterShow=$$v},expression:"filterShow"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.answers.data,"server-items-length":_vm.answers.total,"options":_vm.pagination,"items-per-page":parseInt(_vm.answers.per_page),"footer-props":{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false },"loading":_vm.loading,"show-select":!!_vm.$can('answer.delete'),"must-sort":""},on:{"update:options":[function($event){_vm.pagination=$event},_vm.getAnswers]},scopedSlots:_vm._u([{key:"item.is_correct",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$getColor(item.is_correct),"dark":""}},[_vm._v(" "+_vm._s(item.is_correct === true ? 'correct' : 'wrong')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.$getColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status === true ? 'active' : 'inactive')+" ")])]}},{key:"item.question.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.question.title))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated_at,'YYYY-MM-DD HH:mm'))+" ")]}},(_vm.$can('answer.update'))?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('DataTableAction',{attrs:{"text":_vm.$t('action.edit'),"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$router.push({ name: 'dashboard.answer-update', params: { id: item.id }})}}})]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterShow),expression:"filterShow"}],attrs:{"cols":"12","md":"3"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('action.filters'))+" ")]),_c('v-card-text',[_c('FilterByMultiple',{attrs:{"type":"number","hint":_vm.$t('messages.multiple_values'),"label":_vm.$t('messages.id')},on:{"input":_vm.getAnswers},model:{value:(_vm.filter.id),callback:function ($$v) {_vm.$set(_vm.filter, "id", $$v)},expression:"filter.id"}}),_c('v-select',{attrs:{"items":_vm.quizes,"item-value":"id","item-text":"title","label":_vm.$t('messages.quiz'),"dense":"","outlined":"","clearable":""},on:{"change":function($event){_vm.getQuestions(); _vm.getAnswers()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}}]),model:{value:(_vm.filter.quiz_id),callback:function ($$v) {_vm.$set(_vm.filter, "quiz_id", $$v)},expression:"filter.quiz_id"}}),_c('v-select',{attrs:{"items":_vm.questions,"item-value":"id","item-text":"title","label":_vm.$t('messages.question'),"dense":"","outlined":"","clearable":""},on:{"change":_vm.getAnswers},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$strippedContent(item.title))+" ")]}}]),model:{value:(_vm.filter.question_id),callback:function ($$v) {_vm.$set(_vm.filter, "question_id", $$v)},expression:"filter.question_id"}}),_c('FilterByText',{attrs:{"type":"text","label":_vm.$t('messages.title')},on:{"input":_vm.getAnswers},model:{value:(_vm.filter.title),callback:function ($$v) {_vm.$set(_vm.filter, "title", $$v)},expression:"filter.title"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }