<template>
  <v-select
    v-bind="$attrs"
    dense
    outlined
    clearable
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'VDataTableFilterFieldSelect'
}
</script>
