<template>
  <v-btn
    dark
    fab
    small
    color="indigo darken-4"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon>
      {{ value ? 'mdi-close' : 'mdi-filter-variant' }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ActionFilter',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
}
</script>
