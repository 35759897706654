<template>
  <v-scale-transition>
    <v-btn
      dark
      fab
      small
      color="pink"
      data-test="btn-remove"
      @click="$emit('click')"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'ActionDel'
}
</script>
