<template>
  <v-row>
    <v-col
      cols="12"
      :md="filterShow === false ? 12 : 9"
      class="transition-ease-in-out"
    >
      <v-card>
        <v-card-title>
          {{ $t('messages.answer') }}
        </v-card-title>
        <div class="table-toolbar">
          <remove
            v-if="selected.length > 0 && $can('answer.delete')"
            @click="remove"
          />
          <add
            v-if="$can('answer.create')"
            :to="{ name: 'dashboard.answer-create' }"
          />
          <filters
            v-model="filterShow"
            @click.stop="filterShow = !filterShow"
          />
        </div>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="answers.data"
          :server-items-length="answers.total"
          :options.sync="pagination"
          :items-per-page="parseInt(answers.per_page)"
          :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true, showCurrentPage: true, disableItemsPerPage: false }"
          :loading="loading"
          :show-select="!!$can('answer.delete')"
          must-sort
          class="elevation-1"
          @update:options="getAnswers"
        >
          <template #[`item.is_correct`]="{ item }">
            <v-chip
              :color="$getColor(item.is_correct)"
              dark
            >
              {{ item.is_correct === true ? 'correct' : 'wrong' }}
            </v-chip>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              :color="$getColor(item.status)"
              dark
            >
              {{ item.status === true ? 'active' : 'inactive' }}
            </v-chip>
          </template>
          <template #[`item.question.title`]="{ item }">
            {{ $strippedContent(item.question.title) }}
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template #[`item.updated_at`]="{ item }">
            {{ item.updated_at | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template
            v-if="$can('answer.update')"
            #[`item.action`]="{ item }"
          >
            <DataTableAction
              :text="$t('action.edit')"
              icon="mdi-pencil"
              @click="$router.push({ name: 'dashboard.answer-update', params: { id: item.id }})"
            />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col
      v-show="filterShow"
      cols="12"
      md="3"
    >
      <v-card>
        <v-card-title>
          {{ $t('action.filters') }}
        </v-card-title>
        <v-card-text>
          <FilterByMultiple
            v-model="filter.id"
            type="number"
            :hint="$t('messages.multiple_values')"
            :label="$t('messages.id')"
            @input="getAnswers"
          />
          <v-select
            v-model="filter.quiz_id"
            :items="quizes"
            item-value="id"
            item-text="title"
            :label="$t('messages.quiz')"
            dense
            outlined
            clearable
            @change="getQuestions(); getAnswers()"
          >
            <template #selection="{ item }">
              {{ $strippedContent(item.title) }}
            </template>
            <template #item="{ item }">
              {{ $strippedContent(item.title) }}
            </template>
          </v-select>
          <v-select
            v-model="filter.question_id"
            :items="questions"
            item-value="id"
            item-text="title"
            :label="$t('messages.question')"
            dense
            outlined
            clearable
            @change="getAnswers"
          >
            <template #selection="{ item }">
              {{ $strippedContent(item.title) }}
            </template>
            <template #item="{ item }">
              {{ $strippedContent(item.title) }}
            </template>
          </v-select>
          <FilterByText
            v-model="filter.title"
            type="text"
            :label="$t('messages.title')"
            @input="getAnswers"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import pagination from '@/mixins/pagination'
import DataTableAction from '@/components/table-actions/VDataTableAction'
import { add, remove, filters } from '@/components/actions'
import { FilterByMultiple, FilterByText } from '@/components/table-filter/index'
import authHeader from '@/utils'

export default {
  name: 'Answers',
  components: {
    add, remove, DataTableAction, filters, FilterByMultiple, FilterByText
  },
  mixins: [pagination],
  data () {
    return {
      selected: [],
      filterShow: false,
      loading: true,
      headers: [
        { text: '#', value: 'id' },
        { text: this.$t('messages.title'), value: 'title' },
        { text: this.$t('messages.question'), value: 'question.title' },
        { text: this.$t('messages.quiz'), value: 'question.quiz.title', sortable: false },
        { text: this.$t('messages.is_correct'), value: 'is_correct' },
        { text: this.$t('messages.status'), value: 'status' },
        { text: this.$t('messages.created_at'), value: 'created_at' },
        { text: this.$t('messages.updated_at'), value: 'updated_at' },
        this.$can('answer.update') ? { text: this.$t('messages.actions'), value: 'action', sortable: false, width: '15px', align: 'right' } : {}
      ],
      answers: {
        data: []
      },
      questions: [],
      quizes: [],
      filter: {}
    }
  },
  beforeMount () {
    this.getQuestions()
    this.getQuizes()
  },
  methods: {
    getAnswers () {
      // todo optimize filter
      let filter = ''
      if (this.filter.title) {
        filter += '&title=' + this.filter.title
      }
      if (this.filter.question_id) {
        filter += '&question_id=' + this.filter.question_id
      }
      if (this.filter.quiz_id) {
        filter += '&quiz_id=' + this.filter.quiz_id
      }
      if (this.filter.id) {
        this.filter.id.map(item => {
          filter += '&id[]=' + item
        })
      }
      this.$http
        .get('/answers' + this.datatableOptions + filter, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then((response) => {
          this.loading = false
          this.answers = response.data
        })
    },
    getQuestions () {
      let filter = ''
      if (this.filter.quiz_id) {
        filter += '&quiz_id=' + this.filter.quiz_id
      }
      this.$http
        .get('/questions/?take=500' + filter, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          this.questions = data
        })
    },
    getQuizes () {
      this.$http
        .get('/quizes/?take=500', { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          this.quizes = data
        })
    },
    remove () {
      this.$http
        .delete('/answers', { data: { id: this.selected.map(val => val.id) }, headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(() => {
          // remove ids from current elements
          this.answers.data = this.answers.data.filter((el) => !this.selected.map(val => val.id).includes(el.id))
          this.selected = []
          this.$store.commit('setNotification', { color: 'error', message: this.$t('action.delete_success') })
        })
    }
  }
}
</script>
